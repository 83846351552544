var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("alert-default"),
          _c("alert-title"),
          _c("alert-color"),
          _c("alert-icon"),
          _c("alert-auto-dismiss"),
          _c("alert-v-model-support"),
          _c("alert-animation"),
          _c("alert-example")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }