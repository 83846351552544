var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "v-model support" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVsupport) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can use the ")]),
        _c("code", [_vm._v("v-model")]),
        _c("span", [
          _vm._v(" directive to create two-way data bindings on the ")
        ]),
        _c("code", [_vm._v("show")]),
        _c("span", [_vm._v(" prop as in ")]),
        _c("code", [_vm._v('v-model="showDismissibleAlert"')]),
        _c("span", [_vm._v(" above.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c(
            "b-alert",
            {
              directives: [
                {
                  name: "height-fade",
                  rawName: "v-height-fade.appear",
                  modifiers: { appear: true }
                }
              ],
              staticClass: "mb-0",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showDismissibleAlert,
                callback: function($$v) {
                  _vm.showDismissibleAlert = $$v
                },
                expression: "showDismissibleAlert"
              }
            },
            [
              _c("div", { staticClass: "alert-body" }, [
                _vm._v(" Dismissible Alert! ")
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-1",
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  _vm.showDismissibleAlert = !_vm.showDismissibleAlert
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.showDismissibleAlert ? "Hide" : "Show") +
                  " dismissible alert "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }