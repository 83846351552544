var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Title" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAlertTitle) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Add a title to the alert with the ")]),
        _c("code", [_vm._v(".alert-heading")]),
        _c("span", [_vm._v(" class.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
            _c("h4", { staticClass: "alert-heading" }, [
              _vm._v(" Lorem ipsum dolor sit amet ")
            ]),
            _c(
              "div",
              { staticClass: "alert-body" },
              [
                _vm._v(" Lorem ipsum dolor sit amet "),
                _c("b-link", { staticClass: "alert-link" }, [
                  _vm._v(" consectetur ")
                ]),
                _vm._v(" adipisicing elit. Ducimus, laborum! ")
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }