var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Example" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeExample) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("show")]),
        _c("span", [
          _vm._v(
            " prop to control the visibility state of the alert. By default alerts are not shown. Set the prop "
          )
        ]),
        _c("code", [_vm._v("show")]),
        _c("span", [_vm._v(" to explicitly display them.")])
      ]),
      _c(
        "b-form-group",
        [
          _c("label", [_vm._v("Enter only numbers")]),
          _c("b-form-input", {
            staticClass: "w-25 w-sm-100",
            attrs: { placeholder: "0123456789" },
            on: { keyup: _vm.validateInput },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "height-fade",
              rawName: "v-height-fade.appear",
              modifiers: { appear: true }
            }
          ],
          staticClass: "mb-0",
          attrs: { variant: "danger", show: _vm.show }
        },
        [
          _c(
            "div",
            { staticClass: "alert-body" },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { icon: "InfoIcon" }
              }),
              _vm._v(" the value is invalid you can only enter numbers ")
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }