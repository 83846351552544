var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Auto dismissing alerts" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAutoDismiss) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To create a ")]),
        _c("code", [_vm._v("<b-alert>")]),
        _c("span", [
          _vm._v(
            " that dismisses automatically after a period of time, set the "
          )
        ]),
        _c("code", [_vm._v("show")]),
        _c("span", [_vm._v(" prop (or the ")]),
        _c("code", [_vm._v("v-model")]),
        _c("span", [_vm._v(") to the number of seconds you would like the ")]),
        _c("code", [_vm._v("<b-alert>")]),
        _c("span", [_vm._v(" to remain visible for.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c(
            "b-alert",
            {
              directives: [
                {
                  name: "height-fade",
                  rawName: "v-height-fade.appear",
                  modifiers: { appear: true }
                }
              ],
              staticClass: "mb-0",
              attrs: {
                show: _vm.dismissCountDown,
                dismissible: "",
                variant: "warning"
              },
              on: {
                dismissed: function($event) {
                  _vm.dismissCountDown = 0
                },
                "dismiss-count-down": _vm.countDownChanged
              }
            },
            [
              _c("div", { staticClass: "alert-body" }, [
                _c("span", [
                  _vm._v(
                    "This alert will dismiss after " +
                      _vm._s(_vm.dismissCountDown) +
                      " seconds..."
                  )
                ])
              ])
            ]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-75",
              attrs: { variant: "primary" },
              on: { click: _vm.showAlert }
            },
            [_vm._v(" Show alert with count-down timer ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }