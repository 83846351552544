var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Alert Animation" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeAnimation) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("hightFaded")]),
        _c("span", [
          _vm._v(
            " directive to enable animation. By default alerts are not animated."
          )
        ])
      ]),
      _c(
        "b-alert",
        {
          directives: [{ name: "height-fade", rawName: "v-height-fade" }],
          staticClass: "mb-0",
          attrs: { show: "", dismissible: "", fade: "", variant: "primary" }
        },
        [
          _c("div", { staticClass: "alert-body" }, [
            _c("span", [_vm._v("Dismissible Alert!")])
          ])
        ]
      ),
      _c("b-card-text", { staticClass: "pt-2 mb-0" }, [
        _c("span", [
          _vm._v(
            "If you want to show animation even when alert get rendered. You can use "
          )
        ]),
        _c("code", [_vm._v("appear")]),
        _c("span", [_vm._v(" modifier")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing mb-1" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "primary" },
              on: { click: _vm.showAlert }
            },
            [_vm._v(" Show alert with count-down timer ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  _vm.showDismissibleAlert = true
                }
              }
            },
            [
              _vm._v(
                " Show dismissible alert (" +
                  _vm._s(_vm.showDismissibleAlert ? "visible" : "hidden") +
                  ") "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "height-fade",
              rawName: "v-height-fade.appear",
              modifiers: { appear: true }
            }
          ],
          staticClass: "mb-0",
          attrs: {
            variant: "danger",
            dismissible: "",
            fade: "",
            show: _vm.showDismissibleAlert
          },
          on: {
            dismissed: function($event) {
              _vm.showDismissibleAlert = false
            }
          }
        },
        [
          _c("div", { staticClass: "alert-body" }, [
            _c("span", [_vm._v("Dismissible Alert!")])
          ])
        ]
      ),
      _c("b-card-text", { staticClass: "m-0 py-1" }, [
        _vm._v(" You can even use this on dismissible alerts. ")
      ]),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "height-fade",
              rawName: "v-height-fade.appear",
              modifiers: { appear: true }
            }
          ],
          staticClass: "mb-0",
          attrs: {
            show: _vm.dismissCountDown,
            dismissible: "",
            fade: "",
            variant: "warning"
          },
          on: { "dismiss-count-down": _vm.countDownChanged }
        },
        [
          _c("div", { staticClass: "alert-body" }, [
            _c("span", [
              _vm._v(
                "This alert will dismiss after " +
                  _vm._s(_vm.dismissCountDown) +
                  " seconds..."
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }