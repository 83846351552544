var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Icon" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can use icon inside ")]),
        _c("code", [_vm._v(".alert-body")]),
        _c("span", [_vm._v(" or ")]),
        _c("code", [_vm._v(".alert-heading")]),
        _c("span", [_vm._v(" section.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-spacing-0" },
        [
          _c("b-alert", { attrs: { show: "", variant: "primary" } }, [
            _c(
              "div",
              { staticClass: "alert-body" },
              [
                _c("feather-icon", {
                  staticClass: "mr-25",
                  attrs: { icon: "StarIcon" }
                }),
                _c("span", { staticClass: "ml-25" }, [
                  _vm._v(
                    "Chupa chups topping bonbon. Jelly-o toffee I love. Sweet I love wafer I love wafer."
                  )
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }